import { isHTMLElement } from '~/utils/html';

export function getVerticalScrollPosition(scrollTarget: Window | HTMLElement) {
  return scrollTarget === window
    ? window.pageYOffset || window.scrollY || document.body.scrollTop || 0
    : (scrollTarget as HTMLElement).scrollTop;
}

export function getElementOffset(element: HTMLElement) {
  if (!element) {
    return { top: 0, left: 0 };
  }

  // Return zeros for disconnected and hidden (display: none) elements
  // Support: IE <= 11 only
  // Running getBoundingClientRect on a
  // disconnected node in IE throws an error
  if (element.getClientRects && !element.getClientRects().length) {
    return { top: 0, left: 0 };
  }

  // Get document-relative position by adding viewport scroll to viewport-relative gBCR
  const rect = element.getBoundingClientRect();

  return {
    top: rect.top + window.pageYOffset,
    left: rect.left + window.pageXOffset,
  };
}

export function scrollToFirstError(el?: HTMLElement | null, offset?: number) {
  if (!el) return;

  const firstError = el.querySelector('.osk-field_error');

  if (isHTMLElement(firstError)) {
    const offsetTop = getElementOffset(firstError).top - 8 - (offset ?? 0);

    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    });
  }
}

export function scrollToFirstErrorInContainer(el?: HTMLElement | null) {
  if (!el) return;

  const firstErrorField = el.querySelector('.osk-field_error');

  if (!firstErrorField && !isHTMLElement(firstErrorField)) return;

  const top = firstErrorField?.offsetTop ?? 0;

  el.scrollTo({
    top,
    behavior: 'smooth',
  });
}
